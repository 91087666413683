import React from "react"
import CanadaWaitlist from "../../components/body/pages/en-ca/waitlist"
import Layout from "../../components/layout"
import SEO from "../../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO
      canonical={"https://kuda.com/en-ca"}
      title="The Money App for Africans in Canada | Send Money, Accept Payments, and Transfer Across Borders | Kuda"
      description="Experience seamless cross-border transactions with Kuda. Send money from Canada to Nigeria, accept payments, and enjoy multi-currency transfers."
    />
    <CanadaWaitlist />
  </Layout>
)

export default IndexPage
